import React from 'react';

import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import ScrollButton from '../../utilities/ScrollButton';

import jesus from '../../images/jesus.svg';
import cloudLeft from '../../images/cloud-left.svg';
import cloudRight from '../../images/cloud-right.svg';
import bigCrosses from '../../images/big-crosses.svg';
import waveRed from '../../images/landing-page-wave-red.svg';
import waveBrown from '../../images/landing-page-wave-brown.svg';
import waveLightRed from '../../images/landing-page-wave-light-red.svg';
import basicWire from '../../images/basic-wire.svg';
import plan from '../../images/planspowiedzi.jpg';

import './Home.scss';
import { MdPadding } from 'react-icons/md';

const Home = () => {
  return (
    <div className='animation-wrapper'>
      <main className='default-container animation-section'>
        <Sidebar />
        <Navbar />

        <h1 className='landing-page-title'>
          <span className='first'>
            Parafia Chrystusa Dobrego Pasterza
          </span>{' '}
          w{' '}
          <span className='second'>
            Białych Błotach
          </span>
        </h1>

        <img
          src={cloudLeft}
          alt='chmura'
          className='landing-page-cloud left'
        />
        <img
          src={cloudRight}
          alt='chmura'
          className='landing-page-cloud right'
        />

        <img
          src={jesus}
          alt='Jezus'
          className='landing-page-jesus'
        />

        <img
          src={bigCrosses}
          alt='krzyże'
          className='landing-page-big-crosses'
        />

        <img
          src={waveLightRed}
          alt='jasno czerwona fala'
          className='landing-page-wave light-red'
        />
        <img
          src={waveRed}
          alt='czerwona fala'
          className='landing-page-wave red'
        />
        <img
          src={waveBrown}
          alt='brazowa fala'
          className='landing-page-wave brown'
        />

        <ScrollButton />
      </main>
      <section className='animation-section'>
        <div className='default-container'>
          <Navbar />
          <Sidebar />
          <article className='default-content-wrapper-without-img'>
            <h2 className='subpage-title-center'>
              Ogłoszenia parafialne
            </h2>
            <h3 className='ogloszenia-title'>
              IV Niedziela Wielkiego Postu
            </h3>
            <h4 className='ogloszenia-date'>
              30 marca 2025 r.
            </h4>
            <br />
            <ol
              type='1'
              start='1'
              className='ogloszenia-list'
            >
              <li>
                Dziś gościmy w naszej parafii ks.
                Grzegorza Kwaśniewskiego,
                proboszcza parafii św. Małgorzaty
                w Graboszewie. Ks. Grzegorz głosi
                słowo Boże i po Mszach Świętych
                zbiera ofiary do puszek na remont
                zabytkowego kościoła, w którym
                odkryto polichromie pochodzące z
                XIV wieku. Dziękujemy Kochani za
                pomoc księdzu Grzegorzowi w
                ratowaniu zabytku.
              </li>
              <li>
                Dziękujemy za wszystkie ofiary
                składane na tacę tradycyjną i
                elektroniczną oraz za wszystkie
                ofiary złożone w ostatnim czasie
                na cele gospodarcze i ogrzewanie
                kościoła od rodziny: z ul.
                Chmielarskiej 100 zł., ul.
                Betonowej 200 zł., Hipicznej 150
                zł., ul. Chlebowa 200 zł. Za każdy
                dar serca składamy serdeczne Bóg
                zapłać!
              </li>
              <li>
                Przez okres Wielkiego Postu
                zapraszamy na nabożeństwa pasyjne:
                <ul>
                  <li>
                    Gorzkie Żale z kazaniami
                    pasyjnymi, w każdą niedzielę o
                    godz. 17.15. Po nabożeństwie
                    Gorzkich Żali Msza Święta o
                    godz. 18.00 (recytowana bez
                    homilii).
                  </li>
                  <li>
                    Droga Krzyżowa w każdy piątek
                    o godz: 17.00 – dla dzieci i
                    młodzieży, a o godz: 18.30 dla
                    dorosłych. Za udział w tych
                    nabożeństwach można uzyskać
                    odpust zupełny pod zwykłymi
                    warunkami, który możemy
                    ofiarować za siebie lub
                    jednego ze swoich zmarłych.
                  </li>
                </ul>
              </li>
              <li>
                W tym tygodniu również przypada:
                <ul>
                  <li>
                    I czwartek miesiąca – różaniec
                    z modlitwą o powołania
                    prowadzony przez wspólnoty:
                    SWP, Daru Komunii i Żywego
                    Różańca o godz. 17.30. Okazja
                    do spowiedzi od godz.17.30.
                    Zmiana tajemnic w Kawiarence
                    Dobrego Pasterza o godz.16.30.
                  </li>
                  <li>
                    I piątek miesiąca – okazja do
                    spowiedzi świętej od godz.
                    17.00, Nabożeństwa
                    wynagradzające NSPJ przed Mszą
                    Święta o godz. 8.00 i 18.00.
                  </li>
                  <li>
                    I sobota miesiąca – o godz.
                    7.30 różaniec wraz z
                    modlitwami z racji I soboty.
                    Przedpołudniem odwiedzimy
                    chorych z posługą
                    sakramentalną. Prosimy o
                    dodatkowe zgłoszenia chorych,
                    których również mamy odwiedzić
                    nie zgłoszonych na stałą
                    listę.
                  </li>
                  <li>
                    Za tydzień I niedziela
                    miesiąca na Mszy Świętej o
                    godz. 10.00 odnowienie
                    przyrzeczeń małżeńskich dla
                    par, które ślubowały w
                    miesiącu kwietniu. Po
                    zakończonej Eucharystii
                    zapraszamy na spotkanie przy
                    kawie i ciastku w "Kawiarence
                    Dobrego Pasterza".
                  </li>
                </ul>
              </li>
              <li>
                Od 11 lutego trwamy w naszym
                kościele codziennie o godz. 17.30
                na modlitwie różańcowej w intencji
                Ojczyzny. Dziękujemy wszystkim
                wspólnotom za prowadzenie tej
                modlitwy. W poniedziałek po
                różańcu modlitwy za
                wstawiennictwem św. Ojca Pio
                modlitwę prowadzi Grupa Modlitwy
                Ojca Pio. We wtorek po wieczornej
                Mszy Świętej zapraszamy chętnych
                na otwarte spotkanie modlitewne
                prowadzone przez wspólnotę "Nowe
                Przymierze".
              </li>
              <li>
                Panie z parafialnego Zespołu
                Caritas proszą, aby przynosić
                zaświadczenia z GOPS-u o sytuacji
                materialnej rodziny i potrzebie
                pomocy żywnościowej, którą
                otrzymamy dla mieszkańców naszej
                parafii. O terminie odbierania
                żywności poinformujemy.
              </li>
              <li>
                Wczoraj odbył się kolejny trening
                dla naszej służby liturgicznej na
                Sali gimnastycznej w naszej
                szkole. Dziękujemy jeszcze raz
                Panu Michałowi za poświęcony czas,
                by trenować młode talenty na
                boisku. Zapraszamy chłopców do
                wstępowania w szeregi służby
                liturgicznej oraz parafialnej
                drużyny piłkarskiej. Chętnych na
                kijki zapraszamy w tym tygodniu we
                wtorek bezpośrednio po wieczornej
                Mszy św. około 18.30 przed
                kościołem.
              </li>
              <li>
                Pielgrzymka Bieszczady –
                Podkarpacie ze względu na małą
                ilość osób została odwołana przez
                biuro podróży. Prosimy osoby
                zapisane, które dokonały wpłaty
                700 zł o odbieranie pieniędzy w
                biurze parafialnym. Bardzo
                przepraszamy.
              </li>
              <li>
                W najbliższą środę 2.04 br.
                przypada 20 rocznica śmierci św.
                Jana Pawła II z tej okazji GCK w
                Białych Błotach o godz. 19.00
                organizuje wernisaż poświęcony św.
                Janowi Pawłowi II. Wystawa prac
                Romana Terzyka pt. "Pielgrzym"
                poświęcona naszemu świętemu
                Rodakowi. Wcześniej o godz. 18.00
                zapraszamy na Mszę Świętą do
                naszego kościoła odprawianą przez
                wstawiennictwo św. Jana Pawła II.
                Szczegóły na plakacie.
              </li>
              <li>
                Od jutra dzieci i młodzież z
                naszej szkoły w Białych Błotach
                będą miały nauki rekolekcyjne w
                szkole (poniedziałek i wtorek)
                natomiast w środę w kościele Msza
                Święta dla dzieci z klas 4-8, a
                Droga Krzyżowa dla młodszych
                dzieci. Rekolekcje poprowadzi ks.
                Piotr Poćwiardowski - wikariusz w
                parafii Bożego Ciała w Bydgoszczy
                oraz były moderator Ruchu Światło
                - Życie w diecezji bydgoskiej.
              </li>
              <li>
                W najbliższą sobotę 5 kwietnia po
                Mszy Świętej o godz. 18.00
                serdecznie zapraszamy na koncert
                Pieśni Pasyjnych w wykonaniu
                scholii liturgicznej "Adventus".
              </li>
              <li>
                W najbliższy piątek (4 kwietnia)
                parafia w Cielu zaprasza do
                udziału w Nocnej Drodze Krzyżowej.
                Trasa wyniesie 19,5 km. Wydarzenie
                rozpocznie się Mszą św. o godz.
                20.00. Szczegóły na stronie:
                www.parafiaciele.pl. Natomiast
                bydgoska Ekstremalna Droga
                Krzyżowa Fordon – Opławiec wyruszy
                11 kwietnia trasy 41 i 27 km.
                Szczegóły na: www.edk.org.pl.
              </li>
              <li>
                W piątek 11.04.br około godz.
                18.30 pragniemy przeżyć
                nabożeństwo Drogi Krzyżowej na
                ulicach naszej wsi. Zapraszamy do
                włączenia się w przygotowanie tego
                wydarzenia. Za tydzień podamy
                dokładną trasę i miejsca
                poszczególnych stacji. Zapraszamy
                wszystkie rodziny naszej parafii,
                dzieci, młodzież, harcerzy.
                Zabierzmy ze sobą świece.
                Natomiast 16.04.br. będziemy
                przeżywać dzień spowiedzi w naszej
                parafii z zaproszonymi kapłanami z
                dekanatu.
              </li>
              <li>
                W Wielki Poniedziałek (14
                kwietnia) po Mszy Świętej o godz.
                18.45 zapraszamy wszystkich do
                naszego kościoła na Muzyczne
                Obrazy Drogi Krzyżowej z tekstami
                ks. Jana Twardowskiego oraz muzyką
                Cezarego Chmiela wraz zespołem "De
                Profundis". Recytacji tekstów
                podejmie się znany aktor Andrzej
                Grabowski. Serdecznie zapraszamy.
                Szczegóły na plakacie.
              </li>
              <li>
                Trwają przygotowania do kiermaszu
                wielkanocnego. Grupa artystyczna
                przygotowuje palmy i ozdoby
                wielkanocne podczas warsztatów.
              </li>
              <li>
                Kolejna próba dla dzieci klas 3
                przygotowujących się do I Komunii
                Świętej w czwartek 3 kwietnia o
                godz. 19.00. Obecność wszystkich
                obowiązkowa.
              </li>
              <li>
                Szkoła katolicka w Bydgoszczy
                zaprasza absolwentów szkoły
                podstawowej na Drzwi otwarte do
                katolika w sobotę (5 kwietnia) od
                10.00 do 13.00. Szczegóły na
                stronie szkoły.
              </li>
              <li>
                Centrum Medyczne Ikar w dniu (23
                kwietnia) zaprasza na bezpłatne
                badanie wzroku. Rejestracja pod
                numerem telefonu: 52/322-99-99.
                Szczegóły na plakacie w gablocie.
              </li>
              <li>
                Zachęcamy do czytania prasy
                katolickiej. Przewodniku katolicki
                z dodatkiem na miesiąc kwiecień
                wyłożony pod chórem.
              </li>
              <li>
                W minionym tygodniu odeszła do
                Pana:
                <br />
                + Sabina Brzozowska, lat 93, zam.
                ul. Cedrowa
                <br />
                ...wieczny odpoczynek racz jej dać
                Panie...
              </li>
            </ol>
            {/* <img
              src={plan}
              className='default-border planspowiedzi'
              alt='Plan Spowiedzi'
            ></img> */}
          </article>
          {/* <article className='default-content-wrapper-without-img'>
            <h2 className='subpage-title-center'>
              Plan wizyty duszpasterskiej w roku
              2025
            </h2>
            <h4 className='ogloszenia-title koleda-center '>
              Kolędę w tygodniu od poniedziałku do
              piątku rozpoczynamy od godz. 16.00,
              a w soboty od godz. 11.00.
            </h4>
            <br />
            <br />
            <br />
            <table
              style={{
                width: '100%',
                textAlign: 'center',
                margin: 0,
                padding: 0,
              }}
              class='ogloszenia-list koleda-table'
            >
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Ulica</th>
                  <th>Ulica</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>27.12 2024 r.</td>
                  <td>Ostróżki, Gwarna</td>
                  <td>
                    Hodowlana - od końca, Rzepichy
                  </td>
                </tr>

                <tr>
                  <td>28.12 2024 r.</td>
                  <td>
                    Przemysłowa, Zacisze,
                    Kapliczna, Wierzbowa,
                    Szubińska 14
                  </td>
                  <td>
                    Szubińska (od str. Urzędu
                    Gminy) do nr 87E
                  </td>
                </tr>

                <tr>
                  <td>30.12 2024 r.</td>
                  <td>
                    Hippiczna (str. parzysta)
                  </td>
                  <td>
                    Hippiczna (str. nieparzysta)
                  </td>
                </tr>

                <tr>
                  <td>2.01 2025 r.</td>
                  <td>
                    Temidy, Jantarowa nr 8 i 10
                  </td>
                  <td>Gontowa</td>
                </tr>

                <tr>
                  <td>3.01 2025 r.</td>
                  <td>
                    Czahary (od nr 2-34 i 3-27)
                  </td>
                  <td>
                    Sielska, Czahary (od nr 46-32
                    i 43-33), Sobótki, Parkowa
                  </td>
                </tr>
                <tr>
                  <td>4.01 2025 r.</td>
                  <td>Olchowa, Literacka</td>
                  <td>
                    Jantarowa (od końca), Żeńców,
                    Jutrzenki, Kadetów
                  </td>
                </tr>
                <tr>
                  <td>7.01 2025 r.</td>
                  <td>Ludowa, Uroczyska</td>
                  <td>
                    Modra (od 20 do 2), Letnia,
                    Moczary
                  </td>
                </tr>
                <tr>
                  <td>8.01 2025 r.</td>
                  <td>
                    Barycka (od 1-36- do
                    Czerskiej), Chlebowa 2A, 2 B i
                    2C
                  </td>
                  <td>
                    Barcycka (od 37A-45 i od 40
                    -62), Popiela, Mimozy, Reduty
                  </td>
                </tr>
                <tr>
                  <td>9.01 2025 r.</td>
                  <td>Czerska (od 2 -36)</td>
                  <td>Czerska (od 1-55)</td>
                </tr>
                <tr>
                  <td>10.01 2025 r.</td>
                  <td>
                    Goplany, Herbowa, Hebanowa
                  </td>
                  <td>Guliwera</td>
                </tr>
                <tr>
                  <td>11.01 2025 r.</td>
                  <td>Barwinkowa</td>
                  <td>
                    Alpejska, Arlekina, Bazaltowa,
                    Epokowa
                  </td>
                </tr>
                <tr>
                  <td>13.01 2025 r.</td>
                  <td>
                    Dworska. Jaracza, Judyma
                  </td>
                  <td>
                    Forteczna, Owcza, Jutrzenki 1
                    i 1A, Feniksa
                  </td>
                </tr>
                <tr>
                  <td>14.01 2025 r.</td>
                  <td>
                    Cisowa, Centralna (od 47-59;
                    od 68-96)
                  </td>
                  <td>
                    Centralna (od końca 111-79 i
                    112 -92), Młoda
                  </td>
                </tr>
                <tr>
                  <td>15.01 2025 r.</td>
                  <td>
                    Niedzielna, Miła, Lechicka
                  </td>
                  <td>
                    Mokra, Piesza, Turkusowa,
                    Trawiasta
                  </td>
                </tr>
                <tr>
                  <td>16.01 2025 r.</td>
                  <td>
                    Nizinna, Lutników, Ogniskowa
                  </td>
                  <td>Czerska (od 57-111)</td>
                </tr>
                <tr>
                  <td>17.01 2025 r.</td>
                  <td>
                    Centralna (od 2 – 66 i od 9-
                    25A)
                  </td>
                  <td>
                    Czarnoleska, Chełmska, Cukrowa
                  </td>
                </tr>
                <tr>
                  <td>18.01 2025 r.</td>
                  <td>Czekoladowa</td>
                  <td>
                    Centralna (od 41A-27), Czysta,
                    Bajeczna
                  </td>
                </tr>
                <tr>
                  <td>20.01 2025 r.</td>
                  <td>Chmielna, Chmielarska</td>
                  <td>Cedrowa, Cyprysowa</td>
                </tr>
                <tr>
                  <td>21.01 2025 r.</td>
                  <td>Chlebowa (od 2-62)</td>
                  <td>Gronowa, Czwartaków</td>
                </tr>
                <tr>
                  <td>22.01 2025 r.</td>
                  <td>Chlebowa (od 7 – 83)</td>
                  <td>
                    Chlebowa (od 87 – 105, od 64 –
                    76), Zawiła, Czeremchy
                  </td>
                </tr>
                <tr>
                  <td>23.01 2025 r.</td>
                  <td>Chudoby</td>
                  <td>Cukiernicza, Ulana</td>
                </tr>
                <tr>
                  <td>24.01 2025 r.</td>
                  <td>
                    Duńska, Cała, Ezopa, Boruty
                  </td>
                  <td>Czajcza, Zeusa, Iglasta</td>
                </tr>
                <tr>
                  <td>25.01 2025 r.</td>
                  <td>Daleka, Żaków, Izoldy</td>
                  <td>Drzewna</td>
                </tr>
                <tr>
                  <td>27.01 2025 r.</td>
                  <td>Cynowa, Daliowa</td>
                  <td>
                    Berberysowa, Bałkańska,
                    Bagatela
                  </td>
                </tr>
                <tr>
                  <td>28.01 2025 r.</td>
                  <td>Blok 2</td>
                  <td>Bloki 4 i 6</td>
                </tr>
                <tr>
                  <td>29.01 2025 r.</td>
                  <td>Bracka, Bartnicza</td>
                  <td>
                    Biała, Betonowa 1A, Altanowa
                  </td>
                </tr>
                <tr>
                  <td>30.01 2025 r.</td>
                  <td>
                    Azalowa, Ametystowa,
                    Asfaltowa, Leśna, Jaśminowa
                  </td>
                  <td>
                    Betonowa (od 8- 40), Alibaby
                  </td>
                </tr>
                <tr>
                  <td>31.01 2025 r.</td>
                  <td>
                    Tulipanowa, Konwaliowa,
                    Różana, Żonkilowa, Szafirowa
                  </td>
                  <td>Dodatkowe zgłoszenia</td>
                </tr>
              </tbody>
            </table>

            <table
              style={{
                width: '100%',
                textAlign: 'center',
                margin: 0,
                padding: 0,
              }}
              class='ogloszenia-list koleda-table'
            >
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Ulica</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2.01, 16:00</td>
                  <td>
                    ul. Betonowa blok nr 2, ul.
                    Berberysowa, ul. Bałkańska,
                    ul. Bagatela
                  </td>
                </tr>
                <tr>
                  <td>3.01, 16:00</td>
                  <td>
                    ul. Betonowa blok nr 4 i blok
                    nr 6 oraz ul. Bracka, ul.
                    Bartnicza
                  </td>
                </tr>
                <tr>
                  <td>4.01, 16:00</td>
                  <td>
                    ul. Betonowa domki, ul.
                    Altanowa, ul. Alibaby, ul.
                    Szubińska nr 4-12, ul. Biała,
                    ul. Betonowa 1a
                  </td>
                </tr>
                <tr>
                  <td>5.01, 16:00</td>
                  <td>
                    ul. Azalowa, ul. Ametystowa,
                    ul. Asfaltowa, ul. Jaśminowa,
                    ul. Leśna, ul. Konwaliowa, ul.
                    Szafirowa, ul. Tulipanowa, ul.
                    Żonkilowa, ul. Różana
                  </td>
                </tr>
                <tr>
                  <td>8.01, 16:00</td>
                  <td>
                    ul. Chmielna, ul. Chmielarska,
                    ul. Czarnoleska, ul. Chełmska,
                    ul. Cukrowa
                  </td>
                </tr>
                <tr>
                  <td>9.01, 16:00</td>
                  <td>
                    ul. Chlebowa 7-83, ul.
                    Iglasta, ul. Izoldy, ul.
                    Cedrowa, ul. Cyprysowa
                  </td>
                </tr>
                <tr>
                  <td>10.01, 16:00</td>
                  <td>
                    ul. Chlebowa 2-76, ul.
                    Gronowa, ul. Czwartaków
                  </td>
                </tr>
                <tr>
                  <td>11.01, 16:00</td>
                  <td>
                    ul. Chlebowa 85-105, ul.
                    Boruty, ul. Zawiła, ul.
                    Czeremchy, ul. Cynowa, ul.
                    Daliowa
                  </td>
                </tr>
                <tr>
                  <td>12.01, 16:00</td>
                  <td>
                    ul. Czysta, ul. Bajeczna, ul.
                    Czekoladow
                  </td>
                </tr>
                <tr>
                  <td>13.01, 11:00</td>
                  <td>
                    ul. Chudoby, ul. Cukiernicza,
                    ul. Ulana
                  </td>
                </tr>
                <tr>
                  <td>15.01, 16:00</td>
                  <td>ul. Gontowa, ul. Gwarna</td>
                </tr>
                <tr>
                  <td>16.01, 16:00</td>
                  <td>
                    ul. Drzewna, ul. Czajcza, ul.
                    Zeusa
                  </td>
                </tr>
                <tr>
                  <td>17.01, 16:00</td>
                  <td>
                    ul. Duńska, ul. Ezopa, ul.
                    Daleka, ul. Żaków, ul. Cała
                  </td>
                </tr>
                <tr>
                  <td>18.01, 16:00</td>
                  <td>
                    ul. Guliwera, ul. Modra, ul.
                    Letnia, ul. Moczary
                  </td>
                </tr>
                <tr>
                  <td>19.01, 16:00</td>
                  <td>
                    ul. Ostróżki, ul. Szubińska 87
                    E, ul. Przemysłowa, ul.
                    Wierzbowa, ul. Zacisze, ul.
                    Kapliczna, ul. Szubińska
                  </td>
                </tr>
                <tr>
                  <td>20.01, 11:00</td>
                  <td>
                    ul. Centralna 2-66 i 9-41a,
                    ul. Cisowa, ul. Centralna 47 i
                    68 do 110 i 113
                  </td>
                </tr>
                <tr>
                  <td>22.01, 16:00</td>
                  <td>
                    ul. Jaracza, ul. Czerska
                    57-111, ul. Forteczna, ul.
                    Owcza, ul. Feniksa, ul.
                    Jutrzenki 1 i 1a, ul. Judyma
                  </td>
                </tr>
                <tr>
                  <td>23.01, 16:00</td>
                  <td>
                    ul. Czerska 2-36, ul. Czerska
                    1-55
                  </td>
                </tr>
                <tr>
                  <td>24.01, 16:00</td>
                  <td>
                    ul. Czahary od 2-34 i 3-27,
                    ul. Sielska, ul. Czahary(od
                    numerów końcowych), ul.
                    Sobótki, ul. Parkowa
                  </td>
                </tr>
                <tr>
                  <td>25.01, 16:00</td>
                  <td>
                    ul. Literacka, ul. Olchowa
                  </td>
                </tr>
                <tr>
                  <td>26.01, 16:00</td>
                  <td>
                    ul. Hodowlana, ul. Rzepichy,
                    ul. Ludowa
                  </td>
                </tr>
                <tr>
                  <td>27.01, 11:00</td>
                  <td>
                    ul. Hippiczna - nr
                    nieparzyste, ul. Hippiczna –
                    nr parzyste
                  </td>
                </tr>
                <tr>
                  <td>29.01, 16:00</td>
                  <td>
                    ul. Barwinkowa, ul. Epokowa,
                    ul. Barwinkowa 1-29 i 26-28c
                  </td>
                </tr>
                <tr>
                  <td>30.01, 16:00</td>
                  <td>
                    ul. Goplany, ul. Herbowa, ul.
                    Hebanowa, ul. Dworska, ul.
                    Arlekina, ul. Alpejska, ul.
                    Bazaltowa
                  </td>
                </tr>
                <tr>
                  <td>
                    &nbsp;31.01, 16:00&nbsp;
                  </td>
                  <td>
                    ul. Niedzielna, ul. Miła, ul.
                    Lechicka, ul. Młoda, ul.
                    Turkusowa, ul. Piesza, ul.
                    Mokra
                  </td>
                </tr>
                <tr>
                  <td>1.02, 16:00</td>
                  <td>
                    ul. Temidy, ul. Jantarowej 8 i
                    10, ul. Lutników, ul.
                    Ogniskowa, ul. Trawiasta, ul.
                    Nizinna
                  </td>
                </tr>
                <tr>
                  <td>3.02, 11:00</td>
                  <td>
                    ul. Żeńców, ul. Kadetów, ul.
                    Jantarowa, ul. Uroczysko, ul.
                    Barycka
                  </td>
                </tr>
                <tr>
                  <td>5.02, 16:00</td>
                  <td>
                    ul. Popiela, ul. Moczary, ul.
                    Reduty, ul. Jutrzenki
                  </td>
                </tr>
              </tbody>
            </table>
          </article> */}
          <img
            className='basic-wire'
            src={basicWire}
            alt='fala'
          />
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default Home;
