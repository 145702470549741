import React from 'react';

import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import basicWire from '../../images/basic-wire.svg';

import './Intencje.scss';

const Intencje = () => {
  return (
    <div className='animation-wrapper'>
      <div className=' animation-section'>
        <main className='default-container'>
          <Navbar />
          <Sidebar />
          <section className='default-content-wrapper-without-img'>
            <h1 className='subpage-title-center'>
              Intencje Mszalne
            </h1>
            <article className='flexbox-wrapper intencje-flexbox'>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Niedziela 30.03.2025 r.
                  </h3>
                  <h4 className='intecja-undertitle'>
                    4 Niedziela Wielkiego Postu
                    (laetare)
                  </h4>
                  <br />
                  <b>8:00</b> + rodzice:
                  Bronisława(k) i Tadeusz
                  Pawlikowscy, mąż Franciszek i
                  rodzeństwo
                  <br />
                  <b>8:00</b> + Teresa i Leon
                  Wolni (greg. 26)
                  <br />
                  <b>10:00</b> + Bolesław i
                  Pelagia Pawlak, Edward, Wiktor,
                  Leszek i Zofia
                  <br />
                  <b>10:00</b> + Florian Coda i
                  Eugeniusz Brzeziński - z ok.
                  rocz. śm. oraz za zm. z rodziny
                  <br />
                  <b>12:00</b> Z prośbą o łaskę
                  zdrowia dla Rozalki
                  <br />
                  <b>12:00</b> + Za zm. rodziców i
                  brata – int. od p. Goncerzewicz
                  <br />
                  <b>17:15</b> Gorzkie Żale
                  <br />
                  <b>18:00</b> + Teresa (9 rocz.
                  śm.), Józef i Jan Marchel oraz
                  Marta, Jan i Kazimierz Cieślik
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Niedziela 6.04.2025 r.
                  </h3>
                  <h4 className='intecja-undertitle'>
                    5 Niedziela Wielkiego Postu
                  </h4>
                  <br />
                  <b>8:00</b> + Leokadia i Antoni
                  Łozowieccy – o dar Nieba
                  <br />
                  <b>8:00</b> + Mirosław Walaszek
                  – o dar Nieba
                  <br />
                  <b>10:00</b> Dziękczynna z ok.
                  36 rocz. ślubu Violetty i
                  Krzysztofa – o Boże błog.,
                  opiekę MB Pięknej Miłości i
                  wszelkie łaski
                  <br />
                  <b>12:00</b> O Boże błog.,
                  zdrowie i opiekę MBPM dla Doroty
                  z ok. 59 rocz. ur.
                  <br />
                  <b>17:15</b> Gorzkie Żale
                  <br />
                  <b>18:00</b> + Elżbieta i Ludwik
                  Kwiatkowscy – o dar Nieba
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Poniedziałek 31.03.2025 r.
                  </h3>
                  <br />
                  <b>8:00</b> + Teresa i Leon
                  Wolni (greg. 27)
                  <br />
                  <b>18:00</b> + Bronisław
                  Chrustowski, rodzice i
                  rodzeństwo z obojga stron oraz o
                  zdrowie i Boże błog. dla całej
                  rodziny
                  <br />
                  <b>18:00</b> + W intencji dusz
                  czyśćcowych – o łaskę zbawienia
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Wtorek 1.04.2025 r.
                  </h3>
                  <br />
                  <b>8:00</b> + Teresa i Leon
                  Wolni (greg. 28)
                  <br />
                  <b>18:00</b> + Katarzyna Szynal
                  (36 rocz. śm.) oraz zm. z
                  rodziny
                  <br />
                  <b>18:00</b> + Gieryna
                  Augustyniak (1 rocz. śm.)
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Środa 2.04.2025 r.
                  </h3>
                  <br />
                  <b>8:00</b> + Teresa i Leon
                  Wolni (greg. 29)
                  <br />
                  <b>18:00</b> W intencji Nowenny
                  do MBNP:
                  <br />+ Andrzej Rakowski (1
                  rocz. śm.) – int. od żony i syna
                  <br />+ Krystyna Klimczyk – int.
                  od chrześniaka Andrzeja z
                  Gdańska
                  <br />+ Ryszard Głogowski – z
                  ok. imienin – int. od mamy i
                  rodzeństwa
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Czwartek 3.04.2025 r.
                  </h3>
                  <br />
                  <b>8:00</b> + Teresa i Leon
                  Wolni (greg.30 - zakończenie)
                  <br />
                  <b>18:00</b> Z prośbą o dobre
                  wykorzystanie i przeżycie
                  Wielkiego Postu przez członków
                  Żywego Różańca oraz nową szansę
                  pogłębienia wiary i miłości wraz
                  ze Zmartwychwstałym Jezusem
                  <br />
                  <b>18:00</b> + Ryszard Głogowski
                  – z ok. imienin – int. od żony i
                  dzieci
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Piątek 4.04.2025 r.
                  </h3>
                  <br />
                  <b>8:00</b> + W intencji dusz
                  czyśćcowych – o łaskę zbawienia
                  <br />
                  <b>18:00</b> + zm. rodziców,
                  teściów oraz rodzeństwo Zofia,
                  Zygmunt, Jadwiga, Aldona, zm.
                  szwagrów oraz dusze w czyśćcu
                  cierpiące
                  <br />
                  <b>18:00</b> + Adam Poliwka –
                  int. od siostry Krystyny z
                  rodziną
                </div>
              </div>
              <div className='intencje-flexbox-element'>
                <div className='intencja'>
                  <h3 className='intecja-title'>
                    Sobota 5.04.2025 r.
                  </h3>
                  <br />
                  <b>8:00</b> + W intencji dusz
                  czyśćcowych – o łaskę zbawienia
                  <br />
                  <b>18:00</b> + Edyta Przybyła (z
                  ok. ur.) i Witold Paduch – int.
                  od mamy i żony
                  <br />
                  <b>18:00</b> + Ludwika(k),
                  Zygmunt, Andrzej Kamrowscy oraz
                  Alfons i Honorata Szramowscy
                </div>
              </div>
            </article>
            <img
              className='basic-wire'
              src={basicWire}
              alt='fala'
            />
          </section>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default Intencje;
